import {
  createEffect,
  createMemo,
  on,
  createSignal,
  createSelector,
  //@ts-ignore
  For,
  onCleanup,
} from "solid-js";
import { IntrinsicTextNodeStyleProps, View, activeElement, hexColor, Text, Show } from "@lightningtv/solid";
import { Button, Column } from "@lightningtv/solid-ui";
import { useNavigate, useParams } from "@solidjs/router";
import seriesProvider from "../../api/providers/series";
import { createInfiniteScroll } from "../../components/pagination";
import seriesCategories from "../../api/providers/seriesCategories";
import { LazyUp } from "@lightningtv/solid/primitives";
import LoadingIndicator from "../loading/loading";

const RowStyles: any = {
  display: "flex",
  justifyContent: "flexStart",
  width: 300,
  height: 300,
  // color: hexColor("00000000"),
  gap: 26,
  y: 400,
} satisfies IntrinsicTextNodeStyleProps;

const Series = () => {
  const params = useParams();
  const navigate = useNavigate();
  let timeoutId: ReturnType<typeof setTimeout> | null = null;
  let myButton: any;
  let viewRef: any;
  let search: any;
  let firstRef: any;
  let lastActive: any;
  const [isNumberChanging, setIsNumberChanging] = createSignal(false);

  const providerCategory = createMemo(() => {
    return createInfiniteScroll(seriesCategories(params.filter || "all"));
  });

  const [number, setNumber] = createSignal(null);

  const [showLoading, setShowLoading] = createSignal(true);
  const [showMessage, setShowMessage] = createSignal(false);

  const handleNumberChange = (newNumber: any) => {
    if (number() === newNumber) {
      return;
    }
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setIsNumberChanging(true);
    timeoutId = setTimeout(() => {
      setNumber(newNumber);
      setIsNumberChanging(false);
      timeoutId = null;
    }, 500);
  };

  createEffect(() => {
    const index = Number(localStorage.getItem("seriesIndex"));
    if (index !== null) {
      return
    } else {

      setNumber(providerCategory().pages()[0].categoryID)
      console.log('providerCategory().pages()[0].categoryID', providerCategory().pages()[0].categoryID);


    }
  })

  const provider = createMemo(() => {
    return createInfiniteScroll(seriesProvider(params.filter || "all", number()));
  });

  createEffect(() => {
    localStorage.setItem("@emptyCategory", providerCategory().pages().length > 0 ? "false" : "true");

    const timeout = setTimeout(() => {
      setShowMessage(providerCategory().pages().length > 0 ? false : true); // Show message after 15 seconds
      setShowLoading(false);
    }, 15000);

    // Cleanup timeout on unmount
    onCleanup(() => clearTimeout(timeout));
  });

  function Thumbnail(props: any) {
    const newData = props;

    return (
      <Button
        ref={myButton}
        style={{
          borderRadius: 15,
          border: { width: 0, color: hexColor("#45617a") },
          color: hexColor("#222222"),
          focus: { color: hexColor("#128DD3"), textColor: hexColor("#ffffff") },
          transition: {
            scale: { duration: 250, easing: "ease-in-out" },
          },
          height: 70,
          width: 400,
          justifyContent: "flexStart",
          alignItems: "flexStart",
        }}
        onEnter={() => {
          handleNumberChange(newData.categoryID);
          localStorage.setItem("seriesIndex", newData.index);
          localStorage.setItem("seriesID", newData.categoryID);
          localStorage.removeItem("seriesDetail");
        }}
        states={"active"}
      >
        {newData.category_name}
      </Button>
    );
  }

  function ItemMovie(props: any) {
    const newData = props;

    return (
      <View
        ref={myButton}
        style={{
          borderRadius: 15,
          border: { width: 0, color: hexColor("#45617a") },
          color: hexColor("#222222"),
          focus: { color: hexColor("#128DD3"), textColor: hexColor("#ffffff") },
          transition: {
            scale: { duration: 250, easing: "ease-in-out" },
          },
          height: 225,
          width: 1300,
          justifyContent: "flexStart",
          alignItems: "flexStart",
        }}
        states={"active"}
        onEnter={() => {
          navigate(`/serieDetails/series/${newData.data.series_id}/${newData.data.category_id}`);
          localStorage.setItem("seriesDetail", newData.index);
        }}
      >
        <View src={newData.src} x={10} y={13} style={{ width: 100, height: 145, borderRadius: 10 }}></View>
        <View src={newData.src} x={7} y={7} style={{ width: 130, height: 210, borderRadius: 10 }}></View>
        <View x={20}>
          <Text x={160} y={20} style={{ fontSize: 36 }}>
            {newData.data.name}
          </Text>

          <View x={10} y={70}>
            {Array.from({ length: 5 }, (_, index) => {
              const rating = newData.data.rating_5based;
              const fullStars = Math.floor(rating); // Number of full stars
              const hasHalfStar = rating % 1 >= 0.5; // Check if there's a half star
              const starXPosition = 150 + index * 40; // Calculate x position for each star

              if (index < fullStars) {
                // Render full star
                return (
                  <View
                    src={"https://i.ibb.co/4gK6MnK/star-1.png"}
                    key={index}
                    x={starXPosition}
                    width={30}
                    height={30}
                  />
                );
              } else if (index === fullStars && hasHalfStar) {
                // Render half star if it's the right position
                return (
                  <View
                    src={"https://i.ibb.co/9V7LVwc/rating.png"}
                    width={30}
                    height={30}
                    key={index}
                    x={starXPosition}
                  />
                );
              } else {
                // Render empty star
                return (
                  <View
                    src={"https://i.ibb.co/1KBsPWh/star-2.png"}
                    width={30}
                    height={30}
                    key={index}
                    x={starXPosition}
                  />
                );
              }
            })}
          </View>
          <Text x={160} y={120} style={{ fontSize: 32 }}>
            {newData.data.rating_5based}/5
          </Text>
        </View>
      </View>
    );
  }

  return (
    <View
      color={hexColor("#000000")}
      onEscape={() => {
        navigate("/home");
        localStorage.removeItem("seriesIndex");
        localStorage.removeItem("seriesDetail");
        localStorage.removeItem("seriesID");
      }}
    >
      <View y={30}>
        <Show
          when={providerCategory().pages() && providerCategory().pages().length > 0}
          fallback={
            <View x={750} y={500}>
              <Text fontSize={30} color={hexColor("#7d7e82")}>
                This playlist has no channel data
              </Text>
            </View>
          }
        >
          <View
            x={150}
            y={10}
            onEnter={() => {
              navigate("/searchseries");
              localStorage.removeItem("focusedIndex");
            }}
            // @ts-ignore
            style={{
              borderRadius: 30,
              width: 400,
              height: 70,
              scale: 1,
              zIndex: 2,
              color: hexColor("#222222"),
              transition: { scale: { duration: 250, easing: "ease-in-out" } },
              border: { width: 0, color: 0x00000000 },
              focus: { scale: 1.1, color: hexColor("#128DD3") },
            }}
            ref={search}
            onDown={() => firstRef.setFocus()}
          >
            <View x={25} y={20} width={30} height={30} src="https://i.ibb.co/rHLz71b/search-50.png" />

            <Text x={80} y={25} width={60} style={{ color: hexColor("#7d7e82"), fontSize: 22, width: 10 }}>
              Search Series
            </Text>
          </View>
          <View clipping y={80}>
            {/* @ts-ignore */}
            <LazyUp
              onRight={() => viewRef.setFocus()}
              onUp={() => search.setFocus()}
              x={150}
              y={10}
              gap={10}
              style={RowStyles}
              autofocus={true}
              component={Column}
              ref={firstRef}
              scroll="always"
              direction="column"
              upCount={15}
              selected={
                localStorage.getItem("seriesIndex") === null
                  ? 0
                  : (() => {
                      const index = Number(localStorage.getItem("seriesIndex"));
                      const movieID = Number(localStorage.getItem("seriesID"));

                      if (movieID !== null) {
                        handleNumberChange(movieID);
                      }
                      if (index !== null) {
                        setTimeout(() => {
                          viewRef.setFocus();
                        }, 600);
                        return index;
                      }
                    })()
              }
              each={providerCategory().pages()}
            >
              {(item: any, i: number) => <Thumbnail {...item} index={i} />}
            </LazyUp>

            <View x={570} y={10} clipping>
              <Show
                when={provider().pages() && provider().pages().length > 0 && !isNumberChanging()}
                fallback={
                  // <Text x={300} y={200} style={{ fontSize: 30 }}>Loading ...</Text>
                  <View x={500} y={330} width={100} height={100}>
                    {showLoading() && <LoadingIndicator isCategoriesIndicator={true} />}
                    {showMessage() && (
                      <Text y={50} style={{ color: hexColor("#7d7e82"), fontSize: 22, width: 10 }}>
                        This category is empty
                      </Text>
                    )}
                  </View>
                }
              >
                {/* @ts-ignore */}
                <LazyUp
                  x={20}
                  onLeft={() => firstRef.setFocus()}
                  component={Column}
                  ref={viewRef}
                  scroll="always"
                  direction="column"
                  upCount={6}
                  selected={
                    localStorage.getItem("seriesDetail") === null
                      ? 0
                      : (() => {
                          const index = Number(localStorage.getItem("seriesDetail"));
                          if (index !== null) {
                            setTimeout(() => {}, 300); // Delay of 1 second
                            return index;
                          }
                        })()
                  }
                  each={provider().pages()}
                >
                  {(item: any, i: number) => <ItemMovie {...item} index={i} />}
                </LazyUp>
              </Show>
            </View>
          </View>
        </Show>
      </View>
    </View>
  );
};

export default Series;
